import React, {useEffect, useState} from "react";
import {Container, Row, Col, Card, CardBody, Table} from "reactstrap";


const GeneralReport = (props) => {
    const {machineCount, activeMachineCount, operatorCount} = props;
    
    // STYLES 
    const iconStyle = {
        width: "48px",
        height: "48px",
        borderRadius: "100%",
    };
    return (
            <Container fluid>
                <Card className="text-center">
                    <CardBody>
                        <Row >
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <div className="d-flex align-items-center gap-5 p-3 bg-soft-warning justify-content-center" style={{borderRadius:'5px'}}>
                                    <div className="icon fs-3 bg-soft-warning p-2" style={iconStyle}>
                                        <i className='bx bxs-server text-warning'></i>
                                    </div>
                                    
                                    <div className="info">
                                        <strong className="fs-3">{machineCount}</strong>
                                        <p className="text-muted">{props.t('totalMachine')}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <div className="d-flex align-items-center gap-5 p-3 bg-soft-success justify-content-center" style={{borderRadius:'5px'}}>
                                    <div className="icon fs-3 bg-soft-success p-2" style={iconStyle}>
                                        <i class='bx bx-chevron-right-square text-success'></i>
                                    </div>

                                    <div className="info">
                                        <strong className="fs-3">{activeMachineCount}</strong>
                                        <p className="text-muted">{props.t('activeMachine')}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4}>
                                <div className="d-flex align-items-center gap-5 p-3 bg-soft-secondary justify-content-center" style={{borderRadius:'5px'}}>
                                    <div className="icon fs-3 bg-soft-secondary p-2" style={iconStyle}>
                                        <i class='bx bxs-user text-secondary'></i>
                                    </div>

                                    <div className="info">
                                        <strong className="fs-3">{operatorCount}</strong>
                                        <p className="text-muted">{props.t('totalOperator')}</p>
                                    </div>
                                </div>
                            </Col>
                                    
                        </Row>
                    </CardBody>
                </Card>
            </Container>
    );
};

export default GeneralReport;