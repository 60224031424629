import React, { useEffect, useMemo, useState } from 'react';
import TableContainer from '../../Components/Common/TableContainerReactTable';
import { getFlawDetails } from '../../api/flaw/Flaw';
import { FlawTypeDropdown, removeFlaw } from '../../api/flaw/Flaw';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row,  Button, UncontrolledAlert } from 'reactstrap';

const FlawTable = (props) => {
    const {flaw, getClearImage} = props;
    const [currentFlaw, setCurrentFlaw] = useState(null);
    const [currentRoll, setCurrentRoll] = useState(null);
    const [data, setData] = useState([]);
    const [flawType, setFlawType] = useState([]);

    const [modal, setModal] = useState(false);
    const [flawDetail, setFlawDetail] = useState({});
    const [removeModal, setRemoveModal] = useState(false);

    const [isAlert, setIsAlert] = useState(false);
    const [alertInfo, setAlertInfo] = useState(null);

    const toggle = (flaw_id) => { 
        // flaw detail modal toggle
        // when we close the modal, it returns event object
        // this cause to error in getFlawDetails function
        // so we check the type of flaw_id
        if (typeof(flaw_id) === 'number'){
            getFlawDetails(flaw_id).then((response) => {
                setFlawDetail(response.data);
                setModal(!modal);
            });
        }
        else {
            setModal(!modal);
        };
    };

    const removeToggle = (e, flaw_id, roll_id) => {
        // toggle for remove modal
        e.preventDefault();
        setCurrentFlaw(flaw_id);
        setCurrentRoll(roll_id);
        setRemoveModal(!removeModal);
    };

    const alertToggle = () => {
        setIsAlert(!isAlert);
    };

    useEffect(() => {
        // get flaw type dropdown
        FlawTypeDropdown().then((response) => {
            setFlawType(response.result);
        });
    }, []);

    useEffect(() => {
        try{
            const flaws = flaw.results;
            setData(flaws);
        }
        catch(e){
            // console.log(e);
        }
        
    }, [flaw]);

    const getFlawType = (flaw_type_id) => {
        // get flaw type name from flaw type id
        let flaw_type;
        flawType.map((flaw) => {
            if (flaw[0] === flaw_type_id) {
                flaw_type = flaw[1];
            }
        });
        return flaw_type;
    };

    const handleFlawRemove = async () => {
        // delete selected flaw
        const response = await removeFlaw(currentFlaw);
        if (response.request.status === 204) {
            // set alert
            setAlertInfo({
                type: "success",
                message: props.t('flawDeleteMsg')
            });
            setIsAlert(true);

            // close flaw remove modal 
            setRemoveModal(false);

            // clear alert
            setTimeout(() => {
                setAlertInfo(null);
                setIsAlert(false);
            }, 8000)
        }
        else {
            setAlertInfo({
                type: "danger",
                message: props.t('flawDeleteErrorMsg')
            });
            setIsAlert(true);

            // close flaw remove modal 
            setRemoveModal(false);
        };
    };


    const searchTable = data && data.map((item) => {
        const flaws = {
            "flaw_id" : item.id,
            "flaw_type": getFlawType(item.flaw_type_code),
            "flaw_image": getClearImage(item.image),
            "roll": item.roll,
            "flaw_location": (item.millimeter) / 1000,
            "flaw_location_width": (item.millimeterWidth) / 100,
            "flaw_width": item.flaw_size.width,
            "flaw_height": item.flaw_size.height
        }
        return flaws;
    });


    const columns = useMemo(
        () => [
            // {
            //     Header: 'Hata ID',
            //     accessor: 'flaw_id',
            // },
            {
                Header: props.t('flawImage'),
                accessor: (CellProps) => {
                    return (
                        /*
                            * customize table as image element.
                            * give image source as cellprors flaw_image field.
                            * flaw_image return just path of the image, not full of the url, so
                            * give api url before image path.
                        */
                        <img src={`${process.env.REACT_APP_API_URL}${CellProps.flaw_image}`} 
                            alt="flaw_image" loading='lazy'
                            style={{maxWidth:'48px', maxHeight:'48px', height:'48px', cursor:'pointer'}}
                            onClick={() => toggle(CellProps.flaw_id)}
                        />
                      );
                },
            },
            {
                Header: props.t('roll'),
                accessor: (CellProps) => {
                    return (
                        <p className='mt-2'>{CellProps.roll}</p>
                    )
                }
            },
            {
                Header: props.t('flawType'),
                accessor: (CellProps) => {
                    return (
                        <p className='mt-2'>{CellProps.flaw_type}</p>
                    )
                }
            },
            {
                Header: props.t('flawPlace')  + " (" + props.t('shortMeter') + ")",
                accessor: (CellProps) => {
                    return (
                        <p className='mt-2'>{CellProps.flaw_location}</p>
                    )
                }
            },
            {
                Header: props.t('flawPlaceWidth')  + " (" + props.t('shortCentimeter') + ")",
                accessor: (CellProps) => {
                    return (
                        <p className='mt-2'>{CellProps.flaw_location_width}</p>
                    )
                }
            },
            {
                Header: props.t('flawWidth') + " (" + props.t('shortMillimeter') + ")",
                accessor: (CellProps) => {
                    return (
                        <p className='mt-2'>{CellProps.flaw_width}</p>
                    )
                }
            },
            {
                Header: props.t('flawHeight')  + " (" + props.t('shortMillimeter') + ")",
                accessor: (CellProps) => {
                    return (
                        <p className='mt-2'>{CellProps.flaw_height}</p>
                    )
                }
            },
            {
                Header: props.t('processes'),
                accessor: (CellProps) => {
                    return (
                        <Button 
                            color='outline-danger' 
                            size='sm' 
                            className='w-100 mt-2'
                            onClick={(e) => removeToggle(e, CellProps.flaw_id, CellProps.roll)}>
                            <i className='bx bxs-trash'></i>
                        </Button>
                    )
                }
            }
        ],
        [props.t]
    );

    
    return (
        <div>
            <React.Fragment >
                <UncontrolledAlert 
                    isOpen={isAlert} 
                    toggle={alertToggle} 
                    color={alertInfo && alertInfo.type}
                    style={{ position: 'absolute', zIndex: 2,  top: '8%', right: '12px', left: '12px'}}>
                    {
                        alertInfo &&
                        (
                            alertInfo.message 
                        )
                    }
                </UncontrolledAlert>
                {/* MODALS */}
                <Modal isOpen={modal} 
                    toggle={toggle} 
                    centered={true} 
                    size={"xl"}>
                    <ModalHeader toggle={toggle} className="px-5 ms-3 text-uppercase">
                        <h4>{props.t('flawDetails')}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-6" style={{ display: 'flex', justifyContent:'center', alignItems: 'center'}} >
                                { flawDetail && flawDetail.flaw_coordinate &&
                                    <div >
                                        <img id={`flaw_image_${flawDetail.id}`}  
                                            src={modal && `${flawDetail.image}`} alt="flaw_image"  
                                            loading="lazy"
                                            style={{
                                                    width: '100%', 
                                                    maxWidth: '100%',
                                                    borderRadius: '3px'
                                            }}
                                        />
                                    </div>
                                }
                                
                            </div>
                            <div className="col-md-6 text-center mt-4 fs-5">
                                <div className="row pt-3">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{props.t('flawType')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold fs-5'>{flawDetail && getFlawType(flawDetail.flaw_type_code)}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{props.t('flawWidth')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold'>{modal && flawDetail && flawDetail.flaw_size.width} <span className='text-muted'>{props.t('millimeter')}</span></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{props.t('flawHeight')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold'>{modal && flawDetail && flawDetail.flaw_size.height} <span className='text-muted'>{props.t('millimeter')}</span></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{props.t('flawPlace')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold'>{modal && flawDetail && flawDetail.millimeter/1000} <span className='text-muted'>{props.t('meter')}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={removeModal} 
                    toggle={removeToggle} 
                    centered={true} 
                    size={"md"}>
                    <ModalHeader toggle={removeToggle} className="text-uppercase">
                        <h4>{props.t('flawDelete')}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <p className='d-flex align-items-center gap-3 mt-3 fw-bold'>
                            <i className='bx bxs-error fs-4 text-warning'></i>
                            {props.t('flawDeleteQuestion')}
                        </p>
                        <p className='d-flex align-items-center gap-3 mt-3'>
                            <i className='bx bxs-error-circle fs-5 text-danger'></i>
                            {props.t('flawDeleteWarning')}
                        </p>
                        <Button 
                            className='w-100 mt-3 float-end' 
                            color='outline-danger'
                            onClick={handleFlawRemove}>
                            {props.t('delete')}
                        </Button>
                    </ModalBody>
                </Modal>
                {/* END OF MODALS */}

                <TableContainer
                    flaw={true}
                    columns={(columns || [])}
                    data={(searchTable && searchTable.reverse() || [])}
                    isPagination={true}
                    isGlobalFilter={true}
                    iscustomPageSize={false}
                    isBordered={false}
                    customPageSize={5}
                    className="custom-header-css table align-middle table-nowrap"
                    tableClassName="table-centered align-middle table-nowrap mb-0"
                    theadClassName="text-muted table-light"
                    SearchPlaceholder='Hata Tipi Ara...'
                />
            </React.Fragment >
        </div>
    );
};

export default FlawTable;