import axios from "axios";
import { DecryptToken } from "../../api/helpers/decrypt";

const token = DecryptToken();

const getCompanyPreference = async () => {
    //* get company preference
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/preference/`,
            headers: {
                'Authorization': token
            }
        }).then((res) => {
            resolve(res);
        }
        ).catch((error) => {
            reject(error);
        });
    });

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    };
};

const updateDefectSizePreference = async (id, config) => {
    //* get company preference
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/preference/${id}/`,
            headers: {
                'Authorization': token
            },
            data: {
                defect_size: config
            }
        }).then((res) => {
            resolve(res);
        }
        ).catch((error) => {
            reject(error);
        });
    });

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    };
};

const updateDefectFilterPreference = async (id, config) => {
    //* get company preference
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/preference/${id}/`,
            headers: {
                'Authorization': token
            },
            data: {
                defect_filter: config
            }
        }).then((res) => {
            resolve(res);
        }
        ).catch((error) => {
            reject(error);
        });
    });

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    };
};

const updateDefectPointPreference = async (id, config) => {
    //* get company preference
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/preference/${id}/`,
            headers: {
                'Authorization': token
            },
            data: {
                defect_point: config
            }
        }).then((res) => {
            resolve(res);
        }
        ).catch((error) => {
            reject(error);
        });
    });

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    };
}


const updateReportPreference = async (id, config) => {
    //* get company preference
    const api = new Promise((resolve, reject) => {
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_API_URL}/preference/${id}/`,
            headers: {
                'Authorization': token
            },
            data: {
                report_standard: config
            }
        }).then((res) => {
            resolve(res);
        }
        ).catch((error) => {
            reject(error);
        });
    });

    try{
        const response = await api;
        return response;
    }
    catch (error){
        return error;
    };

}

export {getCompanyPreference, updateDefectSizePreference,
    updateDefectFilterPreference, updateDefectPointPreference, updateReportPreference};