import React, {useState, useEffect} from "react";
import MyCharts  from "./FlawChart";
import PercentChart from "./FlawPercentChart";
import { Card, CardBody, CardHeader, Col, Button, Container, Row } from "reactstrap";
import { BallTriangle, RotatingLines } from "react-loader-spinner";
import { getDailyFlawTypeCount } from "../../api/flawtype/FlawType"


const BarCharts = (props) => {

    const [flaw, setFlaw] = useState([]);
    const [day, setDay] = useState(7)
    const [loader, setLoader] = useState(true);


    async function fetchFlawCount(day){
        //* catch flaw type count according to day
        if (day || day === 0){
            const response = await getDailyFlawTypeCount(day);
            const detail = [];
            Object.entries(response).forEach(([key, value]) => {
                detail.push([value.flaw_type_code__name, value.flaw_count, value.flaw_type_code]);
            });
            setFlaw(detail);
            setLoader(false);
        }
        console.log(day)
    }

    useEffect(() => {
        // to first change date dont call socket
        fetchFlawCount(day)
    }, [day])
  

    const changeDate = (day) => {
        setDay(day)
        setFlaw([])
        setLoader(false)
    };


    return (
        <React.Fragment>
                <Row className="justify-content-start">
                    <Col xs={12} sm={12} md={12} lg={6}>
                        <Card>
                            <div className="loader" 
                                style={{background: '#a5a5a5b5', position: 'absolute', 
                                        top: '0', right:'0', left: '0', bottom: '0', 
                                        zIndex: '1001', display: loader ? 'block': 'none',
                                        overflow: 'hidden', borderRadius: '2px'}}>
                                <div className="loader-animation" style={{position: 'relative', top: '35%', left: '45%', color:'#00425a'}}>
                                    <BallTriangle
                                        color="#00425a"
                                        strokeColor="#00425a"
                                        strokeWidth="3"
                                        animationDuration="1"
                                        width="48"
                                        visible={true}
                                        wrapperStyle={{}}
                                    />
                                </div>
                                <p style={{position: 'relative', top: '35%', left: '45%', translate: '-25px', transform: 'translateY(10px)', color:'#00425a'}}>
                                Lütfen bekleyiniz...
                                </p>
                            </div>
                            <CardHeader>
                                <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                    <h4 className="text-uppercase" style={{float:'left', alignItems:'center'}}>{props.t('flawDist')}</h4>
                                    <div style={{backgroundColor:'#bbbbbb17'}} >
                                        <Button 
                                            className={day === 1 ? "btn btn-primary text-white" : "btn btn-ghost-primary"} 
                                            onClick={() => changeDate(1)}
                                            color='primary' outline
                                            disabled={day === 1}
                                            style={{
                                                backgroundColor: day === 1 ? '#5ea3cb' : 'initial'
                                            }}>
                                            1 {props.t('day')}
                                        </Button>
                                        <Button 
                                            className={day === 3 ? "btn btn-primary text-white" : "btn btn-ghost-primary"} 
                                            onClick={() => changeDate(3)}
                                            color='primary' outline
                                            disabled={day === 3}
                                            style={{
                                                backgroundColor: day === 3 ? '#5ea3cb' : 'initial'
                                            }}>
                                            3 {props.t('day')}
                                        </Button>
                                        <Button 
                                            className={day === 7 ? "btn btn-primary text-white" : "btn btn-ghost-primary"} 
                                            onClick={() => changeDate(7)}
                                            color='primary' outline
                                            disabled={day === 7}
                                            style={{
                                                backgroundColor: day === 7 ? '#5ea3cb' : 'initial'
                                            }}>
                                            7 {props.t('day')}
                                        </Button>
                                        <Button 
                                            className={day === 0 ? "btn btn-primary text-white" : "btn btn-ghost-primary"}
                                            onClick={() => changeDate(0)}
                                            color='primary' outline
                                            disabled={day === 0}
                                            style={{
                                                backgroundColor: day === 0 ? '#5ea3cb' : 'initial'
                                            }}>
                                            {props.t('allDay')}
                                        </Button>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <div className="h-100">
                                    {
                                        flaw.length > 0 ?
                                            <MyCharts flaw={flaw} />
                                        :
                                            <div style={{
                                                height:278,
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'center'}} >
                                                <p className="btn btn-ghost-warning fs-5 ">
                                                <i className='bx bx-smile fs-1 text-warning w-100'></i>
                                                    {props.t('noFlaw')}
                                                </p>
                                            </div>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6}>
                        <Card>
                            <div className="loader" 
                                style={{background: '#a5a5a5b5', position: 'absolute', 
                                        top: '0', right:'0', left: '0', bottom: '0', 
                                        zIndex: '1001', display: loader ? 'block': 'none',
                                        overflow: 'hidden', borderRadius: '2px'}}>
                                <div className="loader-animation" style={{position: 'relative', top: '35%', left: '45%', color:'#00425a'}}>
                                    <BallTriangle
                                        color="#00425a"
                                        strokeColor="#00425a"
                                        strokeWidth="3"
                                        animationDuration="1"
                                        width="48"
                                        visible={true}
                                        wrapperStyle={{}}
                                    />
                                </div>
                                <p style={{position: 'relative', top: '35%', left: '45%', translate: '-25px', transform: 'translateY(10px)', color:'#00425a'}}>
                                    {props.t('loading')}
                                </p>
                            </div>
                            <CardHeader>
                                <h4 className="text-uppercase">{props.t('flawPercentDist')}</h4>
                            </CardHeader>
                            <CardBody>
                                <div className="h-100">
                                    {
                                        flaw.length > 0 ?
                                            <PercentChart flaw={flaw} />
                                        :
                                        <div style={{
                                            height:278,
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'center'}} >
                                            <p className="btn btn-ghost-warning fs-5 ">
                                            <i className='bx bx-smile fs-1 text-warning w-100'></i>
                                                {props.t('noFlaw')}
                                            </p>
                                        </div>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
        </React.Fragment>
    );

};

export default BarCharts;
